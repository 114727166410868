<template>
  <router-view :key="$route.fullPath"/>
</template>

<script>
export default {
  name: 'Index.vue',
  created () {
    console.log('main index')
  },
  beforeRouteEnter (to, from, next) {

  }
}
</script>

<style scoped></style>
